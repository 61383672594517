import React, { Component } from 'react';
import { Container, Row, Col, Alert,Button, Card, CardBody, Form, FormGroup, Input, Label, CustomInput } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import logodark from "../../assets/images/logo-dark.png";
import Validator from '../../component/Common/Validator';
import SimpleReactValidator from 'simple-react-validator';
import { log_in, is_logged_in, free } from '../../api';
import Swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LanguageSwitcherBasic } from '../../lang';

export default injectIntl(class Pagesregister extends Component {
    state = { loading: false, step: 1 };

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            messages: {
                email: props.intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
                in: props.intl.formatMessage({ id: 'PASSWORD.NOT_MATCH' }),
                default: props.intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
            }
        });
    }

    handleRetry(e) {
        e.preventDefault();
        this.setState({ step: 1, signature: null, code: null, password: null, password2: null });
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }

        this.setState({ loading: true });
        try {
            switch(this.state.step) {
                case 1:
                    var signature = await free.post('/register', { name: this.state.name, email: this.state.email, password: this.state.password });
                    this.setState({ step: 2, signature: signature.data });
                break;
                case 2:
                    await free.post(this.state.signature, { name: this.state.name, email: this.state.email, password: this.state.password }, { params: { code: this.state.code } });
                    await log_in(this.state.email, this.state.password);

                    this.setState({ step: 1, signature: null, code: null, password: null, password2: null });
                break;
            }
            this.forceUpdate();
        } catch(e) {
            this.validator.hideMessages();
            Swal.fire('Error', (e && e.message) || 'Error occured, please try again', 'error');
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { intl } = this.props;
        
        if(is_logged_in()) {
            return <Redirect to="/" />
        }

        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="/index" className="logo logo-admin"><img src={logodark} height="30" alt="logo"/></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-3 text-center"><FormattedMessage id="AUTH.SIGNUP" /></h4>
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                { this.state.step === 1 && <>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.NAME" /></Label>
                                                        <Validator name="name" type="required" controller={this}>
                                                            <Input
                                                                type="text"
                                                                value={this.state.name || ""}
                                                                onChange={a => this.setState({ name: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.NAME' })}
                                                                disabled={this.state.loading}
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.EMAIL" /></Label>
                                                        <Validator name="email" type="required|email" controller={this}>
                                                            <Input
                                                                type="text"
                                                                value={this.state.email || ""}
                                                                onChange={a => this.setState({ email: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.EMAIL' })}
                                                                disabled={this.state.loading}
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.PASSWORD" /></Label>
                                                        <Validator name="password" type="required" controller={this}>
                                                            <Input
                                                                type="password"
                                                                value={this.state.password || ""}
                                                                onChange={a => this.setState({ password: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.PASSWORD' })}
                                                                disabled={this.state.loading}
                                                                autoComplete="new-password"
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label><FormattedMessage id="AUTH.NEW_PASSWORD" /></Label>
                                                        <Validator name="password2" type={"required|in:" + this.state.password} controller={this}>
                                                            <Input
                                                                type="password"
                                                                value={this.state.password2 || ""}
                                                                onChange={a => this.setState({ password2: a.currentTarget.value })}
                                                                placeholder={intl.formatMessage({ id: 'AUTH.NEW_PASSWORD' })}
                                                                disabled={this.state.loading}
                                                                autoComplete="new-password"
                                                            />
                                                        </Validator>
                                                    </FormGroup>
                                                    <Row form className="mb-3">
                                                        <Col sm="12">
                                                            <CustomInput disabled={this.state.loading} className="text-left font-14" type="checkbox" id="rememberme"
                                                                label={<FormattedMessage
                                                                    id="AUTH.TOS"
                                                                    values={{
                                                                        a: text => <b>{text}</b>
                                                                    }}
                                                                />}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>}
                                                { this.state.step == 2 &&
                                                    <>
                                                        <FormGroup>
                                                            <Label><FormattedMessage id="AUTH.CODE" /></Label>
                                                            <Validator name="code" type="required" controller={this}>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.code || ""}
                                                                    onChange={a => this.setState({ code: a.currentTarget.value })}
                                                                    placeholder={intl.formatMessage({ id: 'AUTH.CODE' })}
                                                                    disabled={this.state.loading}
                                                                />
                                                            </Validator>
                                                        </FormGroup>
                                                        <p className="text-left font-14">
                                                            <FormattedMessage
                                                                id="AUTH.ENTER_CODE"
                                                                values={{
                                                                    email: <b>{this.state.email}</b>
                                                                }}
                                                            />
                                                        </p>
                                                        <p className="text-left font-14">
                                                            <FormattedMessage
                                                                id="AUTH.USE_DIFFERENT_EMAIL"
                                                                values={{
                                                                    a: text => <a href="#" onClick={this.handleRetry.bind(this)}>{text}</a>
                                                                }}
                                                            />
                                                        </p>
                                                    </>}
                                                <Button block size="lg" color="success" className="font-18" disabled={this.state.loading}>
                                                    { (this.state.loading && <i className="mdi mdi-loading mdi-spin mr-1" />) || <i className="mdi mdi-login mr-1" />}
                                                    <FormattedMessage id="AUTH.COMPLETE_REGISTER" />
                                                </Button>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <LanguageSwitcherBasic />
                                <div className="mt-5 text-center">
                                    <FormattedMessage
                                        id="AUTH.HAVE_AN_ACCOUNT"
                                        values={{
                                            a: text => <Link to="/login">{text}</Link>
                                        }}
                                    />
                                    <p>© 2022 Springboard</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
});
